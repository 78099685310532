import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.autoemails.com/api',
  headers: {
    'Content-Type': 'application/json', // Specify the desired Content-Type here
  },
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default api;
