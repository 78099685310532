import {
  loginStart, loginSuccess, loginFailure, registerStart,
  registerSuccess, registerFailure, forgotPasswordStart,
  forgotPasswordSuccess, forgotPasswordFailure
} from './authSlice';

import api from '../../../config';

export const loginUser = (credentials) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const response = await api.post('/signin', credentials);
    const token = response.data.token;
    localStorage.setItem('token', token);
    dispatch(loginSuccess());
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const registerUser = (values) => async (dispatch) => {
  try {
    dispatch(registerStart());
    await api.post('/register', values);
    dispatch(registerSuccess());
  } catch (error) {
    dispatch(registerFailure(error.response.data.error));
  }
};

export const forgotPassword = (values) => async (dispatch) => {
  try {
    dispatch(forgotPasswordStart());
    await api.post('/forgot-password', values);
    dispatch(forgotPasswordSuccess());
  } catch (error) {
    dispatch(forgotPasswordFailure(error));
  }
};


