import React from 'react'
import Logo from '../../assets/images/logo.png'

const Resetpassword = () => {
  return (
    <>
      <form className="form w-100" method="POST">
        <div className="text-center mb-11">
          <img className="img-fluid w-50 mb-3" src={Logo} alt='' />
          <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
        </div>
 

        <div className="fv-row mb-8">
          <input id="password"
            type="password"
            placeholder="Password"
            className="form-control bg-transparent"
            name="password"
            required autocomplete="email"
            autofocus />
        </div>
        <div className="fv-row mb-8">
          <input id="confirm_password"
            type="password"
            placeholder="Confirm password"
            className="form-control bg-transparent"
            name="confirm_password"
            required />
        </div> 


        <div className="d-grid mb-10">
          <button type="submit" id="kt_sign_in_submit" className="btn btn-dark">
            <span className="indicator-label">Reset password</span>
          </button>
        </div>

      </form>
    </>
  )
}

export default Resetpassword