import api from '../../../../config'; 
import { createSegmentSuccess, deleteSegmentSuccess, getSegmentsSuccess, segmentFailure, segmentStart } from './segmentSlice';

export const createSegment = (values) => async (dispatch) => {
    try {
        dispatch(segmentStart());
        const response = await api.post('/segments/store', values);
        dispatch(createSegmentSuccess(response.data.segment));
    } catch (error) { 
        dispatch(segmentFailure(error.response.data.message));
    }
};

export const getSegments = (values) => async (dispatch) => {
    try {
        dispatch(segmentStart());
        const response = await api.get('/segments/get', values);
        dispatch(getSegmentsSuccess(response.data.segments));
    } catch (error) {
        dispatch(segmentFailure(error.response.data.error));
    }
};

export const deleteSegment = (id) => async (dispatch) => {
    try {
        dispatch(segmentStart());
        const response = await api.get('/segments/delete/'+id);
        dispatch(deleteSegmentSuccess(response.data.id));
    } catch (error) {
        dispatch(segmentFailure(error.response.data.error));
    }
};

