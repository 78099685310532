import React, { useEffect } from 'react'
import { IoClose } from "react-icons/io5";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { createCustomAttribute } from './redux/customAttributeActions';
import { resetCustomAttribute } from './redux/customAttributeSlice';


const AddAttribute = ({ toggleVisibility }) => {

    const dispatch = useDispatch();

    const success = useSelector(state => state.customAttribute.success);
    const error = useSelector(state => state.customAttribute.error);
    const loading = useSelector(state => state.customAttribute.loading);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        type: Yup.string().required('Type is required'),
    });

    const onSubmit = (values, { resetForm }) => {
        dispatch(createCustomAttribute(values)); 
    };
 
    useEffect(() => {
        if (success) {
            dispatch(resetCustomAttribute());
            toggleVisibility(false);
        }
    }, [success, toggleVisibility, dispatch]);

    return (
        <>
            <div className={`modal`}>
                <div className="slideContainer">
                    <div className='slideHeader'>
                        <h3>Add Attribute</h3>
                        <div className='slideClose' onClick={()=>toggleVisibility(false)}>
                            <IoClose size={25} />
                        </div>
                    </div>
                    <div className='slideContent'>
                        {error && <div className='mt-5 alert alert-danger'>{error}</div>}
                        <Formik
                            initialValues={{
                                name: '',
                                type: 'TEXT',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form className="form w-100">
                                    <div className="fv-row mb-8 mt-8">
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            className={`form-control bg-transparent ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                            autoFocus
                                        />
                                        {errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>}
                                    </div>
                                    <div className="fv-row mb-8">
                                        <Field as="select" name="type" className={`form-control bg-transparent ${errors.type && touched.type ? 'is-invalid' : ''}`}>
                                            <option value="TEXT">TEXT</option>
                                            <option value="NUMBER">NUMBER</option>
                                            <option value="DATE">DATE</option>
                                        </Field>
                                        {errors.type && touched.type && <div className="invalid-feedback">{errors.type}</div>}
                                    </div>
                                    <div className="d-grid mb-10">
                                        <button type="submit" className="btn btn-dark">
                                            {loading ? 'Loading...' : 'Create'}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAttribute

