import { createSlice } from '@reduxjs/toolkit';

export const customAttributeSlice = createSlice({
    name: 'customAttribute',
    initialState: {
        customAttributes: [],
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        getAttributesStart: state => {
            state.loading = true;
            state.error = null;
        },
        getAttributesSuccess: (state, action) => {
            state.loading = false;
            state.customAttributes = action.payload; 
        },
        getAttributesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createAttributeStart: state => {
            state.loading = true;
            state.error = null;
        },
        createAttributeSuccess: (state, action) => {
            state.customAttributes = [...state.customAttributes, action.payload];
            state.loading = false;
            state.success = true;
        },
        createAttributeFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteAttributeStart: state => {
            state.loading = true;
            state.error = null;
        },
        deleteAttributeSuccess: (state, action) => {  
            state.customAttributes = state.customAttributes.filter(attribute => attribute.id != action.payload);
            state.loading = false;
            state.success = true;  
        },
        deleteAttributeFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetCustomAttribute: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = false;
        }
    },
});

export const { getAttributesStart, getAttributesSuccess, getAttributesFailure,
    createAttributeStart, createAttributeSuccess, createAttributeFailure,
    resetCustomAttribute, deleteAttributeStart, deleteAttributeSuccess, deleteAttributeFailure } = customAttributeSlice.actions;

export default customAttributeSlice.reducer;
