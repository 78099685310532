import api from './config';


export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return token !== null; 
};
 

export const fetchUserData = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const response = await api.get('/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      return null;
    }
  } else {
    console.warn('No token found, user not authenticated');
    return null;
  }
}; 

