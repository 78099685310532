import api from '../../../../config';
import {
    createSubscriberSuccess,
    deleteSubscriberSuccess, getSubscriberSuccess,
    subscriberFailure, subscriberStart
} from './subscriberSlice';

export const createSubscriber = (values) => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        const response = await api.post('/contacts/store', values);
        dispatch(createSubscriberSuccess(response.data.contacts));
    } catch (error) {
        console.log(error);
        dispatch(subscriberFailure(error.response.data.message));
    }
};

export const getSubscribers = (values) => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        const response = await api.get('/contacts/get', values);
        dispatch(getSubscriberSuccess(response.data.contacts));
    } catch (error) {
        dispatch(subscriberFailure(error.response.data.error));
    }
};

export const deleteSubscriber = (id) => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        const response = await api.get('/contacts/delete/' + id);
        dispatch(deleteSubscriberSuccess(response.data.id));
    } catch (error) {
        dispatch(subscriberFailure(error.response.data.error));
    }
};

