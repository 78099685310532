import React, { useEffect, useMemo, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { defaultAttr } from '../../../constants/attributes';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createSubscriber } from './redux/subscriberActions';
import { resetSubscriber } from './redux/subscriberSlice';

const ContactAdd = ({ isVisible, toggleVisibility, initialValues }) => {

    const dispatch = useDispatch();
    const cusarr = useSelector(state => state.customAttribute.customAttributes);
    const lists = useSelector(state => state.list.lists);

    const success = useSelector(state => state.subscriber.success);
    const error = useSelector(state => state.subscriber.error);
    const loading = useSelector(state => state.subscriber.loading);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
    });

    const onSubmit = (values, { resetForm }) => {
        dispatch(createSubscriber(values));
    };
 
    useEffect(() => {
        if (success) {
            dispatch(resetSubscriber());
            toggleVisibility();
        }
    }, [success])

    return (
        <div className={`slide ${isVisible ? 'show' : ''}`}>
            <div className="slideContainer">
                <div className='slideHeader'>
                    <h3>Add contact</h3>
                    <div className='slideClose' onClick={toggleVisibility}>
                        <IoClose size={25} />
                    </div>
                </div>
                <div className='slideContent'>
                {error && <div className='mt-5 alert alert-danger'>{error}</div>}
                    {initialValues && (<Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form className="form w-100">
                                {defaultAttr.map((item, index) => (
                                    <div className={`fv-row mb-4 ${index === 0 ? 'mt-8' : ''}`} key={index}>
                                        <label>{item.name}</label>
                                        <Field
                                            type={item.type}
                                            name={item.field}
                                            placeholder={item.name}
                                            className="form-control bg-transparent"
                                            autoFocus={index === 0}
                                        />
                                        <ErrorMessage name={item.field} component="div" className="text-danger" />
                                    </div>
                                ))}
                                {cusarr && cusarr.map((item, index) => (
                                    <div className="fv-row mb-4" key={index + 11}>
                                        <label>{item.name}</label>
                                        <Field
                                            type={item.type}
                                            name={item.field}
                                            placeholder={item.name}
                                            className="form-control bg-transparent"
                                        />
                                    </div>
                                ))}
                                <div className="fv-row mb-4" >
                                    <label>Add to a list</label>
                                    <select
                                        name="listId"
                                        placeholder="Choose a list"
                                        className="form-control bg-transparent"
                                        onChange={(e) => { 
                                            setFieldValue('listId', e.target.value);
                                          }}
                                    >
                                        <option value="">Choose a list</option>
                                        {
                                            lists &&
                                            lists.map((item, key) =>
                                                <option key={key} value={item.id}>{item.name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="d-grid mb-10">
                                    <button type="submit" className="btn btn-dark">
                                        {loading ? 'Loading...' : 'Save contact'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ContactAdd;
