import React from 'react'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import Forgotpassword from '../pages/auth/Forgotpassword'
import Resetpassword from '../pages/auth/Resetpassword'
import { Route, Routes } from 'react-router-dom'


const Authlayout = () => {
    return (
        <>
            <div id="kt_body" className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center">
                <div className="d-flex flex-column flex-root" id="kt_app_root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center">
                        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                            <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                                <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                                    <div className="d-flex flex-center flex-column flex-column-fluid pb-5 pb-lg-10">
                                        <Routes>
                                            <Route path='/login' element={<Login />} />
                                            <Route path='/register' element={<Register />} />
                                            <Route path='/forgot-password' element={<Forgotpassword />} />
                                            <Route path='/reset-password' element={<Resetpassword />} />
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Authlayout