import React, { useState } from 'react'
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import ContactList from './subscribers/ContactList';
import Segments from './segments/Segments';
import CustomAttributes from './customattr/CustomAttributes';
import Lists from './lists/Lists';

function Contacts() {
  const location = useLocation();
  const { pathname } = location;
  const parts = pathname.split('/');

  const [tab, setTab] = useState(1);

  const handleTab = (id) => {
    setTab(id);
  }
  return (
    <>
      <div id="kt_app_content_container" className="app-container container-xxl">

        <div className="row g-5 g-xl-10 mb-xl-10">
          <div className="col-12 mb-md-5 mb-xl-10">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5 menu-border-bottom">
                <h3 className="card-title align-items-start flex-column">
                  <div className="tab">
                    <Link to='' className={`tablinks ${!parts[3] || parts[3] == '' ? 'active' : ''}`} onClick={() => handleTab(1)}><h4>Contacts</h4></Link>
                    <Link to='lists' className={`tablinks ${parts[3] === 'lists' ? 'active' : ''}`} onClick={() => handleTab(2)}><h4>Lists</h4></Link>
                    <Link to='segments' className={`tablinks ${parts[3] === 'segments' ? 'active' : ''}`} onClick={() => handleTab(3)}><h4>Segments</h4></Link>
                    <Link to='custom-attributes' className={`tablinks ${parts[3] === 'custom-attributes' ? 'active' : ''}`} onClick={() => handleTab(4)}><h4>Custom fields</h4></Link>
                  </div>
                </h3>
              </div>
              <div className="card-body py-3">
                <Routes>
                  <Route path='/*' element={<ContactList />} />
                  <Route path='/lists' element={<Lists />} />
                  <Route path='/segments' element={<Segments />} />
                  <Route path='/custom-attributes' element={<CustomAttributes />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contacts