import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { mainfilter, operators1, operators2, operators3, operators4 } from '../../constants/filteroptions';
import FilterField from './FilterField';
import { defaultAttr } from '../../constants/attributes';

const FilterSubscriber = ({ customfields }) => {
    const [field, setField] = useState(null);
    const [option2, setOption2] = useState(null);
    const [option3, setOption3] = useState(null);
    const [customField, setCustomField] = useState(null);



    const combinedAttributes = [...defaultAttr, ...customfields];

    const attributesArray = combinedAttributes.map((attribute, index) => {
        return {
            value: attribute.field,
            label: attribute.name,
            ans: attribute.type == 'TEXT' ? 1 : 2,
            opt: attribute.type == 'TEXT' ? 1 : 2,
            cf: 1,
        };
    });

    const handleSelect1Change = selectedOption => {
        setField(selectedOption);
        setOption2(null);
        setOption3(null);

        if(selectedOption.cf == 0){
            setCustomField(null);
        }
      

        if (selectedOption.opt === 1) {
            setOption2(operators1);
        } else if (selectedOption.opt === 2) {
            setOption2(operators2);
        } else if (selectedOption.opt === 3) {
            setOption2(operators3);
        } else if (selectedOption.opt === 4) {
            setOption2(operators4);
        } else if (selectedOption.opt === 5) {
            setCustomField(attributesArray);
        }
    };

    const handleSelect2Change = selectedOption => {
        setOption3(selectedOption);
    };

    return (
        <>
            <div className='filter-section'>
                <div className='filter-inner'>
                    <Select
                        onChange={handleSelect1Change}
                        className='filter-select'
                        placeholder="Choose a filter"
                        options={mainfilter}
                    /> 
                    {
                        customField &&
                        <Select
                            onChange={handleSelect1Change}
                            className='filter-select'
                            placeholder="Choose a field"
                            options={customField}
                        />
                    }
                    {
                        option2 &&
                        <Select
                            onChange={handleSelect2Change}
                            className='filter-select'
                            placeholder="Choose an operator"
                            options={option2}
                        />
                    }
                    {
                        option3 &&
                        <FilterField option={field} />
                    }
                </div>
            </div>
        </>
    )
}

export default FilterSubscriber