// authSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    success: false,
  },
  reducers: {
    loginStart: state => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: state => {
      state.isAuthenticated = true;
      state.loading = false;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    registerStart: state => {
      state.loading = true;
      state.error = null;
    },
    registerSuccess: state => {
      state.success = true;
      state.loading = false;
    },
    registerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    forgotPasswordStart: state => {
      state.loading = true;
      state.error = null;
    },
    forgotPasswordSuccess: state => {
      state.success = true;
      state.loading = false;
    },
    forgotPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    userUpdate: (state, action) => {
      state.user = action.payload
    },
    resetAuth: (state, action) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    }
  },
});

export const { loginStart, loginSuccess, loginFailure,
  registerStart, registerSuccess, registerFailure, forgotPasswordStart, forgotPasswordSuccess, forgotPasswordFailure,
  userUpdate, resetAuth } = authSlice.actions;

export default authSlice.reducer;
