import api from '../../../../config'; 
import { createListSuccess, deleteListSuccess, getListSuccess, listFailure, listStart } from './listSlice';

export const createList = (values) => async (dispatch) => {
    try {
        dispatch(listStart());
        const response = await api.post('/lists/store', values);
        dispatch(createListSuccess(response.data.list));
    } catch (error) { 
        dispatch(listFailure(error.response.data.message));
    }
};

export const getLists = (values) => async (dispatch) => {
    try {
        dispatch(listStart());
        const response = await api.get('/lists/get', values);
        dispatch(getListSuccess(response.data.lists));
    } catch (error) {
        dispatch(listFailure(error.response.data.error));
    }
};

export const deleteList = (id) => async (dispatch) => {
    try {
        dispatch(listStart());
        const response = await api.get('/lists/delete/'+id);
        dispatch(deleteListSuccess(response.data.id));
    } catch (error) {
        dispatch(listFailure(error.response.data.error));
    }
};

