import Mainlayout from "./layouts/Mainlayout";
import './assets/styles/Style.css';
import './assets/styles/Custom.css';
import Authlayout from "./layouts/Authlayout";
import { Navigate, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<Navigate to="/auth/login" />} />
        <Route path="/auth/*" element={<Authlayout />} />
        <Route path="/dashboard/*" element={<Mainlayout />} />
      </Routes>
    </div>
  );
}

export default App;
