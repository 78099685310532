import React, { useEffect, useMemo, useState } from 'react'
import ContactAdd from './ContactAdd';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomAttribute } from '../customattr/redux/customAttributeActions';
import { defaultAttr } from '../../../constants/attributes';
import { getSubscribers } from './redux/subscriberActions';
import { getLists } from '../lists/redux/listActions';
import FilterSubscriber from '../FilterSubscriber';


const ContactList = () => {

  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const subscribers = useSelector(state => state.subscriber.subscribers);
  const cusarr = useSelector(state => state.customAttribute.customAttributes);

  useEffect(() => {
    dispatch(getSubscribers());
    dispatch(getCustomAttribute());
    dispatch(getLists());
  }, [dispatch]);

  const initialValues = useMemo(() => {
    if (!cusarr) {
      return {};
    }
    const combinedAttributes = [...defaultAttr, ...cusarr];
    combinedAttributes.push({ field: "listId" });
    return combinedAttributes.reduce((acc, item) => {
      acc[item.field] = "";
      return acc;
    }, {});

  }, [cusarr]);

  if (subscribers == null) {
    return <p>Loading...</p>
  }

  return (
    <>
      {isVisible && <div className="overlay" onClick={toggleVisibility}></div>}
      <div className=''>
        <button onClick={toggleVisibility} className='btn btn-dark'>Add contact</button>
      </div>
      <div className='filterDiv'>
        <FilterSubscriber customfields={cusarr}/>
      </div>
      {
        subscribers &&
        <div className="table-responsive mt-10">
          <table className="table align-top gs-0 gy-4">
            <thead>
              <tr className="fw-bold text-muted bg-light">
                <td className='ps-4 min-w-300px'>Email</td>
                <td className='min-w-200px'>Name</td>
                <td className='min-w-200px'>Phone</td>
                <td className='min-w-200px'>Company</td>
                <td className='min-w-200px'>Address Line1</td>
                <td className='min-w-200px'>Address Line2</td>
                <td className='min-w-100px'>City</td>
                <td className='min-w-100px'>State</td>
                <td className='min-w-100px'>Zip</td>
                <td className='min-w-200px'>Country</td>
                {cusarr && cusarr.map((item, key) =>
                  <td className='min-w-200px' key={key}>{item.name}</td>
                )
                }
              </tr>
            </thead>
            <tbody>
              {
                subscribers.map((item, key) => {
                  return <tr key={key}>
                    <td> <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">{item.email}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.first_name}&nbsp;{item.last_name}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.phone}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.company}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.address_line_1}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.address_line_2}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.city}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.state}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.zip}</p></td>
                    <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.country}</p></td>
                    {cusarr.map((headerItem, headerIndex) => (
                      <td key={headerIndex}> <p className="text-dark fw-bold d-block mb-1 fs-6">{item[headerItem.field]}</p></td>
                    ))}
                  </tr>
                }
                )
              }
            </tbody>
          </table>
        </div>
      }

      {isVisible &&
        <ContactAdd isVisible={isVisible} toggleVisibility={toggleVisibility} initialValues={initialValues} />
      }
    </>
  )
}

export default ContactList