import api from '../../../../config';
import { createAttributeFailure, createAttributeStart, createAttributeSuccess,
     deleteAttributeFailure,
     deleteAttributeStart,
     deleteAttributeSuccess,
     getAttributesFailure, getAttributesStart, getAttributesSuccess } from './customAttributeSlice';

export const createCustomAttribute = (values) => async (dispatch) => {
    try {
        dispatch(createAttributeStart());
        const response = await api.post('/custom-attributes/store', values);
        dispatch(createAttributeSuccess(response.data.attribute));
    } catch (error) { 
        dispatch(createAttributeFailure(error.response.data.message));
    }
};

export const getCustomAttribute = (values) => async (dispatch) => {
    try {
        dispatch(getAttributesStart());
        const response = await api.get('/custom-attributes/get', values);
        dispatch(getAttributesSuccess(response.data.attributes));
    } catch (error) {
        dispatch(getAttributesFailure(error.response.data.error));
    }
};

export const deleteCustomAttribute = (id) => async (dispatch) => {
    try {
        dispatch(deleteAttributeStart());
        const response = await api.get('/custom-attributes/delete/'+id);
        dispatch(deleteAttributeSuccess(response.data.id));
    } catch (error) {
        dispatch(deleteAttributeFailure(error.response.data.error));
    }
};

