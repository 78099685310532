import { Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { IoClose } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { createList } from './redux/listActions';
import { resetLists } from './redux/listSlice';

const ListAdd = ({ toggleVisibility }) => {
    const dispatch = useDispatch();

    const success = useSelector(state => state.list.success);
    const error = useSelector(state => state.list.error);
    const loading = useSelector(state => state.list.loading);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required')
    });

    const onSubmit = (values, { resetForm }) => {
        dispatch(createList(values));
    };

    useEffect(() => {
        if (success) {
            dispatch(resetLists());
            toggleVisibility(false);
        }
    }, [success, toggleVisibility, dispatch]);

    return (
        <>
            <div className={`modal`}>
                <div className="slideContainer">
                    <div className='slideHeader'>
                        <h3>Add list</h3>
                        <div className='slideClose' onClick={() => toggleVisibility(false)}>
                            <IoClose size={25} />
                        </div>
                    </div>
                    <div className='slideContent'>
                        {error && <div className='mt-5 alert alert-danger'>{error}</div>}
                        <Formik
                            initialValues={{
                                name: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form className="form w-100">
                                    <div className="fv-row mb-8 mt-8">
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            className={`form-control bg-transparent ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                            autoFocus
                                        />
                                        {errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>}
                                    </div>
                                    <div className="d-grid mb-10">
                                        <button type="submit" className="btn btn-dark">
                                            {loading ? 'Loading...' : 'Create'}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListAdd