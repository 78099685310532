import React from 'react'
import Select from 'react-select';

const FilterField = ({ option }) => {

    const groups = [
        { value: '1', label: 'Belongs to', mainfilter: '', opt: 2 },
        { value: '2', label: 'Does not belong to', mainfilter: '', opt: 2 },
        { value: '3', label: 'Belongs to any', mainfilter: '', opt: 2 },
        { value: '4', label: 'Does not belong to any', mainfilter: '', opt: 2 },
    ];

    const source = [
        { value: '1', label: 'Added manually', mainfilter: '', opt: 2 },
        { value: '2', label: 'Imported from file', mainfilter: '', opt: 2 },
    ];

    const country = [
        { value: '1', label: 'United Kingdom' },
        { value: '2', label: 'United States' },
    ];

    switch (option.ans) {
        case 1:
            return <input type="text" className='form-control filter-input' />
        case 2:
            return <Select
                isMulti
                className='filter-select'
                options={groups}
            />
        case 4:
            return <Select
                className='filter-select'
                options={source}
            />
        case 5:
            return <Select
                className='filter-select'
                options={country}
            />
        case 6:
            return <input type="date" className='form-control filter-input' />
        default:
            break;
    }
}

export default FilterField