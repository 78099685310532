export const mainfilter = [
    { value: 'email', label: 'Email', opt: 1, ans:1, cf: 0, },
    { value: 'groups', label: 'Groups', opt: 2, ans:2, cf: 0, },
    { value: 'fields', label: 'Fields', opt: 5, ans:3, cf: 0, },
    { value: 'signup_source', label: 'Signup Source', opt: 3, ans:4, cf: 0, },
    { value: 'location', label: 'Location', opt: 3, ans:5, cf: 0,},
    { value: 'signup_date', label: 'Signup Date', opt: 4, ans:6, cf: 0, }, 
];

export const operators1 = [
    { value: '=', label: 'Equals', mainfilter: '', opt: 1 },
    { value: '!=', label: 'Does not equal', mainfilter: '', opt: 1 },
    { value: 'like', label: 'Contains', mainfilter: '', opt: 1 },
    { value: 'not like', label: 'Does not contain', mainfilter: '', opt: 1 },
];

export const operators2 = [
    { value: '=', label: 'Belongs to', mainfilter: '', opt: 2 },
    { value: '!=', label: 'Does not belong to', mainfilter: '', opt: 2 },
    { value: 'like', label: 'Belongs to any', mainfilter: '', opt: 2 },
    { value: 'not like', label: 'Does not belong to any', mainfilter: '', opt: 2 },
];

export const operators3 = [
    { value: '=', label: 'Is', mainfilter: '', opt: 3 },
    { value: '!=', label: 'Is not', mainfilter: '', opt: 3 },
];

export const operators4 = [
    { value: '>', label: 'Is before', mainfilter: '', opt: 4 },
    { value: '<', label: 'Is after', mainfilter: '', opt: 4 },
];
