import React from 'react'
import logo from '../assets/images/logo.png'
import { Link, useLocation } from 'react-router-dom'
import { HiChartBar, HiUsers } from "react-icons/hi2";
// import { HiCurrencyPound } from "react-icons/hi";
// import { FaTag } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { RiContactsFill } from "react-icons/ri";
import { IoLogOut } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { userUpdate } from '../pages/auth/redux/authSlice';



const Sidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const { pathname } = location;
    const parts = pathname.split('/');

    const logout = () => {
        localStorage.removeItem('token'); 
        dispatch(userUpdate(null));
        window.location.reload();
    };

    return (
        <>
            <div id="kt_app_sidebar" className="app-sidebar flex-column bg-light" data-kt-drawer="true"
                data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true}"
                data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
                <div className="app-sidebar-logo flex-shrink-0 d-none d-md-flex align-items-center px-8"
                    id="kt_app_sidebar_logo">
                    <Link to='/dashboard'>
                        <img alt='' src={logo} className="img-fluid p-10" />
                    </Link>
                    <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                        <div className="btn btn-icon btn-active-color-primary w-30px h-30px"
                            id="kt_aside_mobile_toggle">
                            <i className="ki-outline ki-abstract-14 fs-1"></i>
                        </div>
                    </div>
                </div>

                <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                    <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
                        data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                        data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px">
                        <div className="menu menu-column menu-rounded menu-sub-indention fw-semibold px-3"
                            id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                            <div className="menu-item">
                                <div className="menu-content">
                                    <span
                                        className="menu-heading fw-bold text-uppercase fs-7">Overview</span>
                                </div>
                            </div>

                            <div className="menu-item">
                                <Link className={`fw-bold menu-link ${!parts[2] || parts[2] == '' ? 'active' : ''}`}
                                    to='/dashboard' >
                                    <span className="menu-icon">
                                        <HiChartBar className="fs-2" />
                                    </span>
                                    <span className="menu-title">Dashboard</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className={`fw-bold menu-link ${parts[2] === 'contacts' ? 'active' : ''}`}
                                    to='/dashboard/contacts'>
                                    <span className="menu-icon">
                                        <RiContactsFill className="fs-2" />
                                    </span>
                                    <span className="menu-title">Contacts</span>
                                </Link>
                            </div>
                            {/* <div className="menu-item">
                                <Link className={`fw-bold menu-link ${parts[2] === 'subscription' ? 'active' : ''}`}
                                    to='/dashboard'>
                                    <span className="menu-icon">
                                        <FaTag className="fs-2" />
                                    </span>
                                    <span className="menu-title">Subscription</span>
                                </Link>
                            </div> */}

                            {/* <div className="menu-item">
                                <Link className={`fw-bold menu-link ${parts[2] === 'billing' ? 'active' : ''}`}
                                    to='/dashboard'>
                                    <span className="menu-icon">
                                        <HiCurrencyPound className="fs-2" />
                                    </span>
                                    <span className="menu-title">Billing</span>
                                </Link>
                            </div> */}

                            {/* <div className="menu-item">
                                <Link className={`fw-bold menu-link ${parts[2] === 'users' ? 'active' : ''}`}
                                    to='/dashboard/users'>
                                    <span className="menu-icon">
                                        <HiUsers className="fs-2" />
                                    </span>
                                    <span className="menu-title">Users</span>
                                </Link>
                            </div> */}

                            <div className="menu-item">
                                <Link className="fw-bold menu-link"
                                    to="https://calendly.com/creativemarketingltd/best-proposals">
                                    <span className="menu-icon">
                                        <FaCalendarAlt className="fs-2" />
                                    </span>
                                    <span className="menu-title">Book a demo</span>
                                </Link>
                            </div>

                            <div className="menu-item">
                                <Link className="fw-bold menu-link" onClick={logout}
                                    to=''>
                                    <span className="menu-icon">
                                        <IoLogOut className="fs-2" />
                                    </span>
                                    <span className="menu-title">Logout</span>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="app-sidebar-footer d-flex align-items-center px-8 pb-10" id="kt_app_sidebar_footer">
                    <div className="">
                        <div className="d-flex align-items-center" >
                            <div className="d-flex flex-center cursor-pointer symbol symbol-40px">
                                <img src={`https://eu.ui-avatars.com/api/?name=${user? user.name : 'N'}&background=000&color=fff&bold=true&length=1&size=256`}
                                    alt='' />
                            </div>
                            <div className="d-flex flex-column align-items-start justify-content-center ms-3">
                                <Link to='#'
                                    className="text-gray-800 fs-7 fw-bold text-hover-primary">{user? user.name : 'N'}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Sidebar