import { createSlice } from '@reduxjs/toolkit';

export const listSlice = createSlice({
    name: 'list',
    initialState: {
        lists: [],
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        listStart: state => {
            state.loading = true;
            state.error = null;
        }, 
        listFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getListSuccess: (state, action) => {
            state.loading = false;
            state.lists = action.payload; 
        },
        createListSuccess: (state, action) => {
            state.lists = [...state.lists, action.payload];
            state.loading = false;
            state.success = true;
        }, 
        deleteListSuccess: (state, action) => {  
            state.lists = state.lists.filter(list => list.id != action.payload);
            state.loading = false;
            state.success = true;  
        }, 
        resetLists: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = false;
        }
    },
});

export const { listStart, listFailure, getListSuccess, createListSuccess, deleteListSuccess, resetLists } = listSlice.actions;

export default listSlice.reducer;
