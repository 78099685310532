export const defaultAttr = [
    {
        name: 'First Name',
        type: 'TEXT',
        tag: '{$first_name}',
        field: 'first_name',
        required: true,
    }, 
    {
        name: 'Last Name',
        type: 'TEXT',
        tag: '{$last_name}',
        field: 'last_name',
        required: false,
    }, 
    {
        name: 'Email',
        type: 'TEXT',
        tag: '{$email}',
        field: 'email',
        required: true,
    }, 
    {
        name: 'Phone',
        type: 'TEXT',
        tag: '{$phone}',
        field: 'phone',
        required: false,
    }, 
    {
        name: 'Company',
        type: 'TEXT',
        tag: '{$company}',
        field: 'company',
        required: false,
    }, 
    {
        name: 'Address Line 1',
        type: 'TEXT',
        tag: '{$address_line_1}',
        field: 'address_line_1',
        required: false,
    }, 
    {
        name: 'Address Line 2',
        type: 'TEXT',
        tag: '{$address_line_2}',
        field: 'address_line_2',
        required: false,
    }, 
    {
        name: 'City',
        type: 'TEXT',
        tag: '{$city}',
        field: 'city',
        required: false,
    }, 
    {
        name: 'State',
        type: 'TEXT',
        tag: '{$state}',
        field: 'state',
        required: false,
    }, 
    {
        name: 'Zip',
        type: 'TEXT',
        tag: '{$zip}',
        field: 'zip',
        required: false,
    }, 
    {
        name: 'Country',
        type: 'TEXT',
        tag: '{$country}',
        field: 'country',
        required: false,
    }, 
];