import React from 'react'

const Navbar = () => {
    return (
        <>
            <div className="app-navbar flex-lg-grow-1" id="kt_app_header_navbar">
                <div className="app-navbar-item d-flex  m-5">
                </div>
                <div className="app-navbar-item d-flex ms-md-auto">
                    <div className="ms-1 ms-md-3">
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar