import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, Navigate } from 'react-router-dom';
import Logo from './../../assets/images/logo.png';
import { registerUser } from './redux/authActions'; 

const RegistrationForm = () => {
    const { error, success } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            password_confirmation: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Full name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().required('Password is required').min(6),
            password_confirmation: Yup.string().required('Password is required').oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: async (values, { setSubmitting }) => {
            dispatch(registerUser(values));
            setSubmitting(false);
        }
    });

    if (success) { 
        return <Navigate to="/auth/login" state={{ message: 'Registeration successfull. Please verify you email!' }}/>;
    }

    return (
        <form className="form w-100" onSubmit={formik.handleSubmit}>
            <div className="text-center mb-11">
                <img className="img-fluid w-50 mb-3" src={Logo} alt='' />
                <h1 className="text-dark fw-bolder mb-3">Registration</h1>
                {error && 
                <div className='alert alert-danger'>{error.email}</div>
                }
            </div>

            <div className="fv-row mb-8">
                <input
                    type="text"
                    placeholder="Full name"
                    className="form-control bg-transparent"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                {formik.touched.name && formik.errors.name && <div className="text-danger">{formik.errors.name}</div>}
            </div>

            <div className="fv-row mb-8">
                <input
                    type="email"
                    placeholder="Email address"
                    className="form-control bg-transparent"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && <div className="text-danger">{formik.errors.email}</div>}
            </div>

            <div className="fv-row mb-8">
                <input
                    type="password"
                    placeholder="Password"
                    className="form-control bg-transparent"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password && <div className="text-danger">{formik.errors.password}</div>}
            </div>

            <div className="fv-row mb-8">
                <input
                    type="password"
                    placeholder="Password confirmation"
                    className="form-control bg-transparent"
                    name="password_confirmation"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.password_confirmation && formik.errors.password_confirmation && <div className="text-danger">{formik.errors.password_confirmation}</div>}
            </div>

            <div className="d-grid mb-10 mt-5">
                <button type="submit" className="btn btn-dark" disabled={formik.isSubmitting}>
                    <span className="indicator-label">{formik.isSubmitting ? 'Submitting...' : 'Register'}</span>
                </button>
            </div>

            <div className="text-gray-500 text-center fw-semibold fs-6">Already have an account?
                <Link to='/auth/login' className="link-dark">&nbsp;Log in</Link>
            </div>
        </form>
    );
};

export default RegistrationForm;
